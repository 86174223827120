import * as React from "react";
import Layout from "../components/Layout";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import { Link } from "gatsby";

// markup
const ThankYouConsultation = () => {
  const props = {
    seoTitle: "Thank You Consultation",
    seoDescription:
      "Accelerate your business with Strategix Technology Solutions.",
  };

  return (
    <Layout title={props.seoTitle} description={props.seoDescription}>
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="contact">
            <div className="flex-col-c">
              <h1>Thank You</h1>
              <p>Our team will be in touch soon!</p>
              <Link to="/">
                <button>
                  Return Home <span>&#x279C;</span>
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ThankYouConsultation;
